import { EFormType } from "src/enums/form-type.enum";
import { ENavBaseRoute } from "src/enums/nav-base-route.enum";
import { EPageType } from "src/enums/page-type.enum";
import { ERouteParams } from "src/enums/route-param.enums";
import { CouponRoutes } from "../coupons.routes";
import { ENavCategory } from "src/enums/nav-category.enum";

const BASE_ROUTE = `/${ENavBaseRoute.management}/${ENavCategory.marketing}/coupon-groups`;

const CREATE_ROUTE = `${BASE_ROUTE}/${EPageType.form}/${EFormType.create}`;
const LIST_ROUTE = `${BASE_ROUTE}/${EPageType.list}`;
const FORM_ROUTE = `${BASE_ROUTE}/${EPageType.form}/:formType/:id?`;
const EDIT_ROUTE = `${BASE_ROUTE}/${EPageType.form}/${EFormType.edit}/:id`;
const COPY_ROUTE = `${BASE_ROUTE}/${EPageType.form}/${EFormType.copy}/:id`;
const TAB_ROUTE = `${CouponRoutes.LIST_ROUTE}${ERouteParams.Tab}COUPON_GROUPS`;

export const CouponGroupRoutes = {
	BASE_ROUTE,
	EDIT_ROUTE,
	CREATE_ROUTE,
	COPY_ROUTE,
	LIST_ROUTE,
	FORM_ROUTE,
	TAB_ROUTE,
};