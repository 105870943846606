import { EFormType } from "src/enums/form-type.enum";
import { ERouteParams } from "src/enums/route-param.enums";
import { EPageType } from "src/enums/page-type.enum";
import { CouponRoutes, ECouponEditTab, ECouponManagementTabKey } from "../coupons.routes";

const _BASE_ROUTE = `${CouponRoutes.BASE_ROUTE}/:couponId/promo-codes`;
const CREATE_ROUTE = `${_BASE_ROUTE}/${EPageType.form}/${EFormType.create}`;
const CREATE_BULK_ROUTE = `${_BASE_ROUTE}/${EPageType.form}/bulk-create`;
const TAB_ROUTE = `${CouponRoutes.LIST_ROUTE}${ERouteParams.Tab}${ECouponManagementTabKey.PromoCodes}`;
const LIST_ROUTE = TAB_ROUTE;
const COUPON_PROMO_CODE_LIST_ROUTE = `${CouponRoutes.EDIT_ROUTE}?tab=${ECouponEditTab.PromoCodes}`;
const FORM_ROUTE = `${_BASE_ROUTE}/${EPageType.form}/:formType/:id?`;
const EDIT_ROUTE = `${_BASE_ROUTE}/${EPageType.form}/${EFormType.edit}/:id`;
const COPY_ROUTE = `${_BASE_ROUTE}/${EPageType.form}/${EFormType.copy}/:id`;

export const PromoCodeRoutes = {
	BASE_ROUTE: CouponRoutes.BASE_ROUTE,
	EDIT_ROUTE,
	CREATE_ROUTE,
	CREATE_BULK_ROUTE,
	TAB_ROUTE,
	COPY_ROUTE,
	FORM_ROUTE,
	LIST_ROUTE,
	COUPON_PROMO_CODE_LIST_ROUTE,
};